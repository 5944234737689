<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">会员详情</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="edit()" icon="el-icon-refresh"
            >返回</el-button>
			<el-button class="refresh1" @click="refresh()" icon="el-icon-refresh"
			  >刷新</el-button>
        </div>
		
      </div>
	  <table  style="border-collapse:collapse;height: 250px;border: 1px solid gainsboro;margin-top: 50px;margin-left: 250px;">
	      <tr style="border-bottom: 1px solid gainsboro">
	        <td  rowspan=5; style="width:250px;" align="center">
		       <tr>111</tr>
			   <tr>电话:{{labelList.member_tel}}</tr>
			   <tr>{{rank}}</tr>
	        </td>
	        <td style="width:100px;text-align: right;background-color: #F5F5F5	;">用户ID：</td>
	        <td style="width:300px">{{labelList.member_name}}</td>
	        <td style="width:100px; background-color: #F5F5F5	;">职业：</td>
	        <td style="width:300px">{{labelList.occupation}}</td>
	      </tr>
	      <tr style="border-bottom: 1px solid gainsboro">
	        <!-- <td  rowspan=3>111</td> -->
	        <td style="width:100px;text-align: right;background-color: #F5F5F5	;">微信昵称：</td>
	        <td style="width:300px">{{nick}}</td>
	        <td style="width:100px; background-color: #F5F5F5	;">个性签名：</td>
	        <td style="width:300px">帅</td>
	      </tr>
	      <tr style="border-bottom: 1px solid gainsboro">
	        <!-- <td  rowspan=3>111</td> -->
	        <td style="width:100px;text-align: right;background-color: #F5F5F5	;">性别：</td>
	        <td style="width:300px" v-if="labelList.sex==1">男</td>
	        <td style="width:300px" v-else>女</td>
	        <td style="width:100px; background-color: #F5F5F5	;">喜欢的分类：</td>
	        <td style="width:300px">帅</td>
	      </tr>
	      <tr >
	        <!-- <td  rowspan=3>111</td> -->
	        <td style="width:100px;text-align: right;background-color: #F5F5F5	;">生日：</td>
	        <td style="width:300px">{{labelList.birthday}}</td>
	        <td style="width:100px; background-color: #F5F5F5;">注册时间：</td>
	        <td style="width:300px">{{labelList.rtime}}</td>
	      </tr>
	      <tr style="border-top: 1px solid gainsboro">
	        <!-- <td  rowspan=3>111</td> -->
	        <td style="width:100px;text-align: right;background-color: #F5F5F5	;">城市：</td>
	        <td style="width:300px">{{labelList.city}}</td>
	        <td style="width:100px; background-color: #F5F5F5;">用户来源：</td>
	        <td style="width:300px">{{labelList.source}}</td>
	      </tr>
	    </table>
	  <div class="table" style="margin-top: 10%;margin-bottom: 20px;">
	    <div style="margin-right: 65%;font-size: 20px;">统计信息</div>
	    <el-table
	      :data="labelList"
	      border
	      style="width: 85%; margin-left: 200px; text-align: center;margin-top: 20px;"
	    >
	      <el-table-column prop="ml_id" label="消费金额" align="center">
	      </el-table-column>
	      <el-table-column prop="l_name" label="订单数量" align="center">
	      </el-table-column>
	  
	  
	      <el-table-column prop="labelCount" label="可用积分" align="center">
	      </el-table-column>
	      <el-table-column prop="end_time" label="成长值" align="center">
	  		
	      </el-table-column>
	     <el-table-column prop="end_time" label="优惠券(张)" align="center">
	     	  		
	     </el-table-column>
		 <el-table-column prop="end_time" label="关注店铺" align="center">
		 	  		
		 </el-table-column>
		 <el-table-column prop="end_time" label="收藏商品" align="center">
		 	  		
		 </el-table-column>
		 <el-table-column prop="end_time" label="邀请好友" align="center">
		 	  		
		 </el-table-column>
	     
	    </el-table>
	  </div>
      
      <div class="table" style="margin-top: 5%;margin-bottom: 20px;">
        <div style="margin-right: 65%;font-size: 20px;">收货地址</div>
        <el-table
          :data="labelList"
          border
          style="width: 85%; margin-left: 200px; text-align: center;margin-top: 20px;"
        >
          <el-table-column prop="ml_id" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="l_name" label="手机号" align="center">
          </el-table-column>
      
      
          <el-table-column prop="labelCount" label="详细地址" align="center">
          </el-table-column>
          <el-table-column prop="end_time" label="邮政编码" align="center">
      			  
          </el-table-column>
         <el-table-column prop="end_time" label="默认地址" align="center">
               			  
         </el-table-column>
        
        </el-table>
      </div>
      
      
      <div class="table" style="margin-top: 5%;margin-bottom: 20px;">
        <div style="margin-right: 65%;font-size: 20px;">订单记录</div>
        <el-table
          :data="labelList"
          border
          style="width: 85%; margin-left: 200px; text-align: center;margin-top: 20px;"
        >
          <el-table-column prop="ml_id" label="订单编号" align="center">
          </el-table-column>
          <el-table-column prop="l_name" label="提交时间" align="center">
          </el-table-column>

      
          <el-table-column prop="labelCount" label="用户id" align="center">
          </el-table-column>
          <el-table-column prop="end_time" label="订单金额" align="center">
		
          </el-table-column>
		  <el-table-column prop="end_time" label="支付方式" align="center">
		  		
		  </el-table-column>
         <el-table-column prop="end_time" label="订单状态" align="center">
         		
         </el-table-column>
          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
             
              <span
                style="margin-left: 10px"
                
              >
                查看订单</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="100"
        >
        </el-pagination>
        <div></div>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "6-1",
      top2: ["6"],
      flag: 1,
      admin_type: "",
	  xstoreid:'',
      labelList:[],
      selects:'',
	  selectp:'',
      activeTitle: "",
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
      business_id: '', */
      businessList: [{}],
      business_id: "",

      storeList: [{}],
      storeid: "",
      //添加活动
      rank:'',
	  nick:'',
      //修改活动
    
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      // tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
 
    //修改上下架
  
    //刷新页面按钮
    refresh() {
      location.reload();
    },
 
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      // console.log("789", this.currentpage);
     
    },
   edit()
   {
	   this.$router.go(-1)
   },
  shopType(value){
  	let token = this.$storage.getLocal('token');
  	this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
  		console.log(123)
  		if(r.code == 0){ 
  			// this.selectp = r.data;
  			console.log(r)
  		}
  	})
  },
  storeidType(value)
  {
  	this.xstoreid=value
  },
    //编辑活动的提交按钮
 
  
 
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
	console.log(this.$route.query)
	
   let login_type=this.$storage.getLocal('type')
   let token = this.$storage.getLocal('token');
   let det=this.$route.query
   this.rank=det.rank
   this.nick=det.nick
   this.$request.memberInfo({token,m_id:det.id}).then(r=>{
   	// console.log(123)
   	if(r.code == 0){ 
   		console.log(r)
		this.labelList=r.data.userData
   	}
   })

   if(login_type=='1')
   {
   	this.$request.getMerchantData({token}).then(r=>{
   		if(r.code == 0){ 
   			// this.selects = r.data;
   			console.log(r)
   	
   		}
   	})
   }else if(login_type=='2')
   {
   	this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
   		if(r.code == 0){ 
   			// this.selectp = r.data;
   			console.log(r)
   		}
   	})
   }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.refresh1 {
  /* border:1px solid red ; */
  float: right;
  margin-right: 1%;
  margin-top: 2px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
  margin-bottom: 50px;
}
.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 200px;
		border: 1px solid black;
	
	}
	.sel
	{
		/* margin-left: 10px; */
		margin-top: 5px;
	}
</style>